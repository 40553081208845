import { setActiveProductAgentForTxHistory } from 'app/customers/duck/actions';
import { ProductAgents } from 'app/customers/duck/states';
import { ConfirmPlatformRechargeModal } from 'app/customers/product-agents/ConfirmPlatformRechargeModal';
import { WireTransferRechargeEntryModal } from 'app/customers/product-agents/WireTransferRechargeEntryModal';
import { AppState } from 'app/duck/states';
import {
  IdType,
  ListResult,
  ProductAgentAccountTransaction,
  ProductAgentAccountTxStatus,
  ProductAgentAccountTxType,
} from 'model';
import { memo, useCallback, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { productAgentService } from 'services';
import {
  AsideRight,
  PaymentMethodLabel,
  ProductAgentAccountTxCategoryLabel,
  ProductAgentAccountTxStatusLabel,
  ProductAgentAccountTxTypeLabel,
  getString,
} from 'shared/components';
import { Button, Column, DataTable } from 'shared/metronic/components';
import { formatTime } from 'utils';

export const TxHistory = memo(() => {
  const dispatch = useDispatch();
  const [showWireTransferEntryModal, setShowWireTransferEntryModal] =
    useState(false);
  const [refresh, setRefresh] = useState(0);
  const agents = useSelector<AppState, ProductAgents>(
    state => state.customers.productAgents,
  );
  const activeAgentIdForTxHistory = agents.activeAgentIdForTxHistory;
  const activeAgent = activeAgentIdForTxHistory
    ? agents.result?.find(
        x => String(x.id) === String(activeAgentIdForTxHistory),
      )
    : undefined;

  const onClose = useCallback(() => {
    dispatch(setActiveProductAgentForTxHistory(undefined));
  }, [dispatch]);

  return (
    <AsideRight
      open={activeAgentIdForTxHistory != null}
      onClose={onClose}
      width={window.innerWidth * 0.75}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '0.5rem',
        }}
      >
        <ul
          className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
          style={{ marginBottom: 15 }}
        >
          <li className="nav-item m-tabs__item">
            <a
              className="nav-link m-tabs__link active"
              style={{ fontWeight: 'bold', fontSize: '120%' }}
            >
              <Translate
                id="product_agent.tx_history.title"
                data={{ account_name: activeAgent?.name }}
              />
            </a>
          </li>
        </ul>
        <Button
          color="default"
          icon
          onClick={() => setShowWireTransferEntryModal(true)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i className="fa fa-plus" style={{ marginRight: '0.5rem' }} />
          <Translate id="product_agent.tx_history.btn.entry_wire_transfer_transaction" />
        </Button>
      </div>
      {activeAgentIdForTxHistory ? (
        <Content
          key={[activeAgentIdForTxHistory, refresh].join('-')}
          agentId={activeAgentIdForTxHistory}
        />
      ) : null}
      <WireTransferRechargeEntryModal
        open={showWireTransferEntryModal}
        agentId={activeAgentIdForTxHistory ?? undefined}
        onClose={() => setShowWireTransferEntryModal(false)}
        onDone={() => {
          setRefresh(x => x + 1);
          setShowWireTransferEntryModal(false);
        }}
      />
    </AsideRight>
  );
});

const Content = memo(({ agentId }: { agentId: IdType }) => {
  const [transactions, setTransactions] =
    useState<ListResult<ProductAgentAccountTransaction>>();
  const [error, setError] = useState<Error>();
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [confirmPlatformRechargeTargetTx, setConfirmPlatformRechargeTargetTx] =
    useState<{ agentId: IdType; txId: string }>();

  const refresh = useCallback(() => {
    productAgentService
      .searchAccountTransactions(agentId, offset, limit)
      .then(setTransactions)
      .catch(setError);
  }, [agentId, limit, offset]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns: Column<ProductAgentAccountTransaction>[] = [
    {
      prop: 'txId',
      align: 'left',
      text: getString('product_agent.col.tx.id'),
      width: 150,
    },
    {
      prop: 'txCategory',
      align: 'center',
      text: getString('product_agent.col.tx.category'),
      width: 70,
      render: ({ txCategory }) => (
        <ProductAgentAccountTxCategoryLabel value={txCategory} />
      ),
    },
    {
      prop: 'txType',
      align: 'center',
      text: getString('product_agent.col.tx.type'),
      width: 70,
      render: ({ txType }) => <ProductAgentAccountTxTypeLabel value={txType} />,
    },
    {
      prop: 'paymentMethod',
      align: 'center',
      text: getString('product_agent.col.tx.payment_method'),
      width: 100,
      render: ({ paymentMethod }) => (
        <PaymentMethodLabel value={paymentMethod} />
      ),
    },
    {
      prop: 'amount',
      align: 'right',
      text: getString('product_agent.col.tx.amount'),
      width: 70,
      render: ({ amount }) => (amount / 100).toFixed(2),
    },
    {
      prop: 'balance',
      align: 'right',
      text: getString('product_agent.col.tx.balance'),
      width: 70,
      render: ({ balance }) => (balance / 100).toFixed(2),
    },
    {
      prop: 'pendingBalance',
      align: 'right',
      text: getString('product_agent.col.tx.pending_balance'),
      width: 100,
      render: ({ pendingBalance }) => (pendingBalance / 100).toFixed(2),
    },
    {
      prop: 'status',
      align: 'center',
      text: getString('product_agent.col.tx.status'),
      width: 100,
      render: ({ status, txId, txType }) =>
        txType === ProductAgentAccountTxType.Recharge &&
        status === ProductAgentAccountTxStatus.PendingEntry ? (
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setConfirmPlatformRechargeTargetTx({ agentId, txId });
            }}
          >
            <ProductAgentAccountTxStatusLabel value={status} />
          </a>
        ) : (
          <ProductAgentAccountTxStatusLabel value={status} />
        ),
    },
    {
      prop: 'txCreateTime',
      align: 'center',
      text: getString('product_agent.col.tx.create_time'),
      width: 150,
      render: ({ txCreateTime }) => formatTime(txCreateTime),
    },
    {
      prop: 'txSuccessTime',
      align: 'center',
      text: getString('product_agent.col.tx.success_time'),
      width: 150,
      render: ({ txSuccessTime }) =>
        txSuccessTime ? formatTime(txSuccessTime) : '-',
    },
    {
      prop: 'txClosedAt',
      align: 'center',
      text: getString('product_agent.col.tx.closed_at'),
      width: 100,
      render: ({ txClosedAt }) => (txClosedAt ? formatTime(txClosedAt) : '-'),
    },
    {
      prop: 'closeReason',
      align: 'center',
      text: getString('product_agent.col.tx.close_reason'),
      width: 100,
      render: ({ closeReason }) => closeReason ?? '-',
    },
  ];

  return (
    <>
      <DataTable
        data={transactions?.items}
        error={error}
        columns={columns}
        idProp="id"
        selModel={'none'}
        limit={limit}
        onLimitChange={setLimit}
        offset={offset}
        onOffsetChange={setOffset}
        total={transactions?.total}
      />
      <ConfirmPlatformRechargeModal
        open={confirmPlatformRechargeTargetTx != null}
        agentId={confirmPlatformRechargeTargetTx?.agentId}
        txId={confirmPlatformRechargeTargetTx?.txId}
        onClose={() => setConfirmPlatformRechargeTargetTx(undefined)}
        onDone={() => {
          refresh();
          setConfirmPlatformRechargeTargetTx(undefined);
        }}
      />
    </>
  );
});
