/**
 * @file: ProductAgentAccountTxTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductAgentAccountTxType } from 'model';

export const ProductAgentAccountTxTypeOptions: Array<
  Option<ProductAgentAccountTxType>
> = [
  {
    value: ProductAgentAccountTxType.Recharge,
    label: '@string/product_agent_account_tx_type.recharge',
  },
  {
    value: ProductAgentAccountTxType.RechargeEntry,
    label: '@string/product_agent_account_tx_type.recharge_entry',
  },
  {
    value: ProductAgentAccountTxType.Withdraw,
    label: '@string/product_agent_account_tx_type.withdraw',
  },
  {
    value: ProductAgentAccountTxType.Refund,
    label: '@string/product_agent_account_tx_type.refund',
  },
  {
    value: ProductAgentAccountTxType.CommissionFee,
    label: '@string/product_agent_account_tx_type.commission_fee',
  },
  {
    value: ProductAgentAccountTxType.PlatformCharge,
    label: '@string/product_agent_account_tx_type.platform_charge',
  },
];

export const ProductAgentAccountTxTypeOptionsWithDefault: Array<
  Option<ProductAgentAccountTxType | null>
> = [
  { value: null, label: 'product_agent_account_tx_type.__default__' },
  {
    value: ProductAgentAccountTxType.Recharge,
    label: 'product_agent_account_tx_type.recharge',
  },
  {
    value: ProductAgentAccountTxType.RechargeEntry,
    label: 'product_agent_account_tx_type.recharge_entry',
  },
  {
    value: ProductAgentAccountTxType.Withdraw,
    label: 'product_agent_account_tx_type.withdraw',
  },
  {
    value: ProductAgentAccountTxType.Refund,
    label: 'product_agent_account_tx_type.refund',
  },
  {
    value: ProductAgentAccountTxType.CommissionFee,
    label: 'product_agent_account_tx_type.commission_fee',
  },
  {
    value: ProductAgentAccountTxType.PlatformCharge,
    label: 'product_agent_account_tx_type.platform_charge',
  },
];
