import { IdType, RechargeProductAgentAccountParams } from 'model';
import { memo, useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { productAgentService } from 'services';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  getString,
} from 'shared/components';
import { Alert, Button } from 'shared/metronic/components';

type FormInfo = Omit<RechargeProductAgentAccountParams, 'amount'> & {
  amount: number | undefined;
};

export const WireTransferRechargeEntryModal = memo(
  ({
    agentId,
    open,
    onDone,
    onClose,
  }: {
    agentId?: IdType;
    open?: boolean;
    onDone?: () => void;
    onClose?: () => void;
  }) => {
    const [formInfo, setFormInfo] = useState<FormInfo>({
      amount: undefined,
      bankName: '',
      bankTxId: '',
      wechatPayBankOrderNo: '',
      wechatPayTxId: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [fieldErrors, setFieldErrors] = useState<
      Record<keyof FormInfo, string | undefined>
    >({} as any);

    const onChange = useCallback((changes: Partial<FormInfo>) => {
      if ('amount' in changes && changes.amount != null && changes.amount > 0) {
        setFieldErrors(errors => ({ ...errors, amount: undefined }));
      }
      if ('bankName' in changes && changes.bankName?.trim()) {
        setFieldErrors(errors => ({ ...errors, bankName: undefined }));
      }
      if ('bankTxId' in changes && changes.bankTxId?.trim()) {
        setFieldErrors(errors => ({ ...errors, bankTxId: undefined }));
      }
      if (
        'wechatPayBankOrderNo' in changes &&
        changes.wechatPayBankOrderNo?.trim()
      ) {
        setFieldErrors(errors => ({
          ...errors,
          wechatPayBankOrderNo: undefined,
        }));
      }
      if ('wechatPayTxId' in changes && changes.wechatPayTxId?.trim()) {
        setFieldErrors(errors => ({ ...errors, wechatPayTxId: undefined }));
      }
      setFormInfo(values => ({ ...values, ...changes }));
    }, []);

    const form = useMemo(() => {
      const builder = new EntityEditorFormBuilder<FormInfo>();
      builder
        .text({
          prop: 'bankName',
          label:
            'product_agent.tx_history.modal.entry_wire_transfer.label.bank_name',
          placeholder:
            'product_agent.tx_history.modal.entry_wire_transfer.placeholder.bank_name',
          error: fieldErrors.bankName,
        })
        .text({
          prop: 'bankTxId',
          label:
            'product_agent.tx_history.modal.entry_wire_transfer.label.bank_tx_id',
          placeholder:
            'product_agent.tx_history.modal.entry_wire_transfer.placeholder.bank_tx_id',
          error: fieldErrors.bankTxId,
        })
        .text({
          type: 'number',
          prop: 'amount',
          label:
            'product_agent.tx_history.modal.entry_wire_transfer.label.amount',
          placeholder:
            'product_agent.tx_history.modal.entry_wire_transfer.placeholder.amount',
          error: fieldErrors.amount,
        })
        .text({
          prop: 'wechatPayBankOrderNo',
          label:
            'product_agent.tx_history.modal.entry_wire_transfer.label.wechat_pay_bank_order_no',
          placeholder:
            'product_agent.tx_history.modal.entry_wire_transfer.placeholder.wechat_pay_bank_order_no',
          error: fieldErrors.wechatPayBankOrderNo,
        })
        .text({
          prop: 'wechatPayTxId',
          label:
            'product_agent.tx_history.modal.entry_wire_transfer.label.wechat_pay_tx_id',
          placeholder:
            'product_agent.tx_history.modal.entry_wire_transfer.placeholder.wechat_pay_tx_id',
          error: fieldErrors.wechatPayTxId,
        })
        .text({
          prop: 'remarks',
          label:
            'product_agent.tx_history.modal.entry_wire_transfer.label.remark',
          placeholder:
            'product_agent.tx_history.modal.entry_wire_transfer.placeholder.remark',
          error: fieldErrors.remarks,
        });
      return builder.build();
    }, [fieldErrors]);

    const onConfirm = useCallback(async () => {
      try {
        if (agentId == null) return;

        let hasErrors = false;
        if (!formInfo.bankName.trim()) {
          setFieldErrors(errors => ({
            ...errors,
            bankName: getString(
              'product_agent.tx_history.modal.entry_wire_transfer.error.bank_name_required',
            ),
          }));
          hasErrors = true;
        }
        if (!formInfo.bankTxId.trim()) {
          setFieldErrors(errors => ({
            ...errors,
            bankTxId: getString(
              'product_agent.tx_history.modal.entry_wire_transfer.error.bank_tx_id_required',
            ),
          }));
          hasErrors = true;
        }
        if (!formInfo.amount || formInfo.amount < 0) {
          setFieldErrors(errors => ({
            ...errors,
            amount: getString(
              'product_agent.tx_history.modal.entry_wire_transfer.error.amount_required',
            ),
          }));
          hasErrors = true;
        }
        if (!formInfo.wechatPayBankOrderNo.trim()) {
          setFieldErrors(errors => ({
            ...errors,
            wechatPayBankOrderNo: getString(
              'product_agent.tx_history.modal.entry_wire_transfer.error.wechat_pay_bank_order_no_required',
            ),
          }));
          hasErrors = true;
        }
        if (!formInfo.wechatPayTxId.trim()) {
          setFieldErrors(errors => ({
            ...errors,
            wechatPayTxId: getString(
              'product_agent.tx_history.modal.entry_wire_transfer.error.wechat_pay_tx_id_required',
            ),
          }));
          hasErrors = true;
        }
        if (hasErrors) return;
        setLoading(true);
        await productAgentService.rechargeAccountWithWireTransfer(agentId, {
          ...formInfo,
          amount: Math.round(formInfo.amount! * 100),
        });
        onDone?.();
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }, [agentId, formInfo, onDone]);

    return (
      <Modal isOpen={open} style={{ width: 650 }}>
        <ModalHeader>
          <Translate id="product_agent.tx_history.modal.entry_wire_transfer.title" />
        </ModalHeader>
        <ModalBody>
          <ReactMarkdown>
            {getString(
              `product_agent.tx_history.modal.entry_wire_transfer.msg`,
            )}
          </ReactMarkdown>
          {error && <Alert color="danger">{error.message}</Alert>}
          <form className="entity-editor-form m-form">
            <div className="m-portlet__body">
              <div className="m-form__section m-form__section--first">
                <EntityEditorForm
                  entity={formInfo}
                  onChange={onChange}
                  elements={form.elements}
                  autocomplete={form.autocomplete}
                  useUncontrolled={form.useUncontrolled}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" disabled={loading} onClick={onClose}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button
            color="primary"
            loader={loading}
            disabled={loading}
            onClick={onConfirm}
          >
            <Translate id="product_agent.tx_history.modal.entry_wire_transfer.btn.confirm_entry" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
