/**
 * @file: PaymentMethodOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, PaymentMethod } from 'model';

export const PaymentMethodOptions: Array<Option<PaymentMethod>> = [
  { value: PaymentMethod.None, label: '@string/payment_method.none' },
  {
    value: PaymentMethod.WechatPay,
    label: '@string/payment_method.wechat_pay',
  },
  {
    value: PaymentMethod.WireTransfer,
    label: '@string/payment_method.wire_transfer',
  },
];

export const PaymentMethodOptionsWithDefault: Array<
  Option<PaymentMethod | null>
> = [
  { value: null, label: 'payment_method.__default__' },
  { value: PaymentMethod.None, label: 'payment_method.none' },
  { value: PaymentMethod.WechatPay, label: 'payment_method.wechat_pay' },
  { value: PaymentMethod.WireTransfer, label: 'payment_method.wire_transfer' },
];
