/**
 * @file: ProductAgentAccountTxType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductAgentAccountTxType {
  Recharge = 'recharge',
  RechargeEntry = 'recharge_entry',
  Withdraw = 'withdraw',
  Refund = 'refund',
  CommissionFee = 'commission_fee',
  PlatformCharge = 'platform_charge',
}

export const ProductAgentAccountTxTypeValueSet = new Set([
  ProductAgentAccountTxType.Recharge,
  ProductAgentAccountTxType.RechargeEntry,
  ProductAgentAccountTxType.Withdraw,
  ProductAgentAccountTxType.Refund,
  ProductAgentAccountTxType.CommissionFee,
  ProductAgentAccountTxType.PlatformCharge,
]);
