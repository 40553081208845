import { IdType } from 'model';
import { memo, useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { productAgentService } from 'services';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  getString,
} from 'shared/components';
import { Alert, Button } from 'shared/metronic/components';

type FormInfo = {
  platformCharge: number | undefined;
};

export const ConfirmPlatformRechargeModal = memo(
  ({
    agentId,
    txId,
    open,
    onDone,
    onClose,
  }: {
    agentId?: IdType;
    txId?: string;
    open?: boolean;
    onDone?: () => void;
    onClose?: () => void;
  }) => {
    const [formInfo, setFormInfo] = useState<FormInfo>({
      platformCharge: undefined,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [platformChargeError, setPlatformChargeError] = useState<string>();

    const onChange = useCallback((changes: Partial<FormInfo>) => {
      if (
        'platformCharge' in changes &&
        changes.platformCharge != null &&
        changes.platformCharge > 0
      ) {
        setPlatformChargeError(undefined);
      }
      setFormInfo(values => ({ ...values, ...changes }));
    }, []);

    const form = useMemo(() => {
      const builder = new EntityEditorFormBuilder<FormInfo>();
      builder.text({
        type: 'number',
        prop: 'platformCharge',
        label:
          'product_agent.tx_history.modal.confirm_platform_recharge.platform_charge.label',
        placeholder:
          'product_agent.tx_history.modal.confirm_platform_recharge.platform_charge.placeholder',
        helpText: (
          <Translate
            id="product_agent.tx_history.modal.confirm_platform_recharge.platform_charge.help_text"
            data={{
              page_link: (
                <a
                  href="https://pay.weixin.qq.com/index.php/xphp/cmktfundflow/capital_flow"
                  target="_blank"
                  rel="noreferrer"
                >
                  page
                </a>
              ),
              screenshot: (
                <img
                  src="/img/wechat-pay-platform-charge.png"
                  alt=""
                  style={{ width: '100%' }}
                />
              ),
            }}
          />
        ),
        error: platformChargeError,
      });
      return builder.build();
    }, [platformChargeError]);

    const onConfirm = useCallback(async () => {
      try {
        if (agentId == null || txId == null) return;
        if (!formInfo.platformCharge || formInfo.platformCharge < 0) {
          setPlatformChargeError(
            getString(
              'product_agent.tx_history.modal.confirm_platform_recharge.platform_charge.error.required',
            ),
          );
          return;
        }
        setLoading(true);
        await productAgentService.confirmAccountRechargeTx(
          agentId,
          txId,
          Math.round(formInfo.platformCharge * 100),
        );
        onDone?.();
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }, [agentId, formInfo.platformCharge, onDone, txId]);

    return (
      <Modal isOpen={open} style={{ width: 550 }}>
        <ModalHeader>
          <Translate id="product_agent.tx_history.modal.confirm_platform_recharge.title" />
        </ModalHeader>
        <ModalBody>
          <ReactMarkdown>
            {getString(
              `product_agent.tx_history.modal.confirm_platform_recharge.msg`,
            )}
          </ReactMarkdown>
          {error && <Alert color="danger">{error.message}</Alert>}
          <form className="entity-editor-form m-form">
            <div className="m-portlet__body">
              <div className="m-form__section m-form__section--first">
                <EntityEditorForm
                  entity={formInfo}
                  onChange={onChange}
                  elements={form.elements}
                  autocomplete={form.autocomplete}
                  useUncontrolled={form.useUncontrolled}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" disabled={loading} onClick={onClose}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button
            color="primary"
            loader={loading}
            disabled={loading}
            onClick={onConfirm}
          >
            <Translate id="product_agent.tx_history.modal.confirm_platform_recharge.btn.confirm_entry" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
